const tableData = [
    {
      registrationcode: 'CMR-EIDPOC-0001',
      region: 'Littoral',
      dateOfLastTraining: '2021/02/24',
      phone: '675636363',
      email: 'joel@admin.com',
      jobTitle:'Supervisor',
      name:'John doe',
      facility:'Laboratoire',

      },{
      registrationcode: 'CMR-EIDPOC-0001',
      region: 'Littoral',
      dateOfLastTraining: '2021/02/24',
      phone: '675636363',
      email: 'joel@admin.com',
      jobTitle:'Supervisor',
      name:'John doe',
      facility:'Laboratoire',

      },{
      registrationcode: 'CMR-EIDPOC-0001',
    region: 'Littoral',
      dateOfLastTraining: '2021/02/24',
      phone: '675636363',
      email: 'joel@admin.com',
      jobTitle:'Supervisor',
      name:'John doe',
      facility:'Laboratoire',

      },
  {
      registrationcode: 'CMR-EIDPOC-0001',
      region: 'Littoral',
      dateOfLastTraining: '2021/02/24',
      phone: '675636363',
      email: 'joel@admin.com',
      jobTitle:'Supervisor',
      name:'John doe',
      facility:'Laboratoire',

      },

];

export { tableData };
