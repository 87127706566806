<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Trainers</h4>
                            </div>
                            <div class="col-md-7">
                            </div>
                            <div class="col-md-2">
                                <b-button v-b-modal.modal-standard variant="success">
                                    <i class="ri-add-line align-middle"></i> Add Trainee
                                </b-button>
                                <b-modal
                                        id="modal-standard"
                                        ref="modal-standard"
                                        title="Add Refresher Trainee"
                                        title-class="font-18"
                                        hide-footer
                                >
                                    <form @submit.prevent="addTrainee">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label >Select Trainee</label>
                                                <multiselect v-model="value" label="name_and_surname" :options="traineeData" :multiple="false" placeholder="Select trainee"></multiselect>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label > Refresher training </label>
                                                    <date-picker v-model="srefresher_date" range append-to-body lang="en" placeholder="Refresher Training" confirm></date-picker>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary" type="submit">Add</button>
                                    </form>
                                </b-modal>
                            </div>
                            <div class="col-md-1">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="refresherTraineeData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" variant="info" v-b-tooltip.hover v-b-modal.view-trainee title="View Details" @click.prevent="view(item)">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="success" v-b-tooltip.hover v-b-modal.update-trainee title="Edit" @click.prevent="eidt(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger" v-b-modal.modal-delete v-b-tooltip.hover title="Delete" @click.prevent="view(item)">
                                        <i class="ri-delete-bin-2-fill align-middle " ></i>
                                    </b-button>
                                    <!--                                    <span><b-btn @click="editItem(item)">Edit</b-btn></span>-->


                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <b-modal
                                        id="view-trainee"
                                        v-if="selectedId"
                                        centered
                                        size="xl"
                                        title="view Trainee"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form >
                                        <div class="row form-group">
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Name: </div>
                                                    <div class="mr-3 align-self-center h6"> {{this.selectedId.name_and_surname}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Region :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.region.name}} </div>
                                                </div>

                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Site :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.institution}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">S/N  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.sn}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Responsibility :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.responsibility}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Phone :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.phone}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Email  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.email}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Refresher date :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedId.refresher_date}} </div>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- <button class="btn btn-primary" href=""  type="submit">Edit</button> -->
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="update-trainee"
                                        v-if="selectedId"
                                        centered
                                        size="xl"
                                        title="view Trainee"
                                        title-class="font-18"
                                        ref="update-trainee"
                                        hide-footer

                                >
                                    <form @submit.prevent="updateTrainee" ref="form">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Name and Surname</label>
                                                    <input
                                                            type="text"
                                                            placeholder="Name"
                                                            class="form-control"
                                                            v-model="selectedId.name_and_surname"
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Region </label>
                                                    <multiselect  v-model="selectedId.region" label="name" :options="regionData" :multiple="false" placeholder="Select Region"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Site</label>
                                                    <input
                                                            type="text"
                                                            v-model="selectedId.institution"
                                                            placeholder="Site"
                                                            class="form-control"
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > S/N</label>
                                                    <input
                                                            type="text"
                                                            v-model="selectedId.sn"
                                                            placeholder="S/N "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Responsibility</label>
                                                    <input
                                                            type="text"
                                                            placeholder="Responsibility "
                                                            class="form-control"
                                                            v-model="selectedId.responsibility"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Phone</label>
                                                    <input
                                                            v-model="selectedId.phone"
                                                            type="number"
                                                            placeholder="Phone "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Email</label>
                                                    <input
                                                            type="email"
                                                            v-model="selectedId.email"
                                                            placeholder="Email "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Refresher date</label>
                                                    <date-picker v-model="srefresher_date" range append-to-body lang="en" placeholder="Date of training" confirm></date-picker>
                                                </div>
                                            </div>


                                        </div>
                                        <button class="btn btn-primary text-right" type="submit">Update</button>
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="modal-delete"
                                        centered
                                        title="Confirm Delete"
                                        title-class="font-18"
                                        ref="modal-delete"
                                        hide-footer

                                >
                                    <center>  <span  >Are you sure you want to Delete?</span></center>
                                    <div class="text-center">
                                        <button
                                                class="btn btn-danger w-md waves-effect waves-light mt-4"
                                                @click="hideModal"
                                        >No</button>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4 mx-3"
                                                type="submit"
                                                @click.prevent="del()"
                                        >Yes</button>
                                    </div>
                                </b-modal>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>                                
    </Layout>

</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";

    import { tableData } from "./dataTrainers";
    import {axios_get, axios_delete, axios_put} from "../../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View trainers",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,Multiselect,DatePicker, PageHeader },
        data() {
            return {

                tableData: tableData,
                loader:false,
                title: "View all trainers",
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "View trainers",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "sn", sortable: true },
                    { key: "name_and_surname", sortable: true },
                    { key: "region_name", sortable: true },
                    { key: "date_of_training", sortable: true },
                    { key: "phone", sortable: true },
                    { key: "email", sortable: true },
                    // { key: "email", sortable: true },
                    // { key: "facilityEmail", sortable: true },
                    { key: "refresher_date", sortable: true },
                    { key: "actions", sortable: false },
                ],
                traineeData:[],
                refresherTraineeData:[],
                refresher_date:"",
                srefresher_date: null,
                selectedId:null,
                value:null,



            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.refresherTraineeData.length;
            }
        },
        mounted() {
            this.countries=JSON.parse(localStorage.getItem('user'))
            this.country_id=this.countries.country_id
            console.log(this.country_id)
            this.totalRows = this.items.length;
            axios_get("/trainee",{},(d)=>{
                console.log(d)
                this.traineeData=d
                this.traineeData=this.traineeData.filter(e=>{return e.refresher_date==="None" && e.region.country_id==this.country_id})
                console.log(this.traineeData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );

            axios_get("/regions",{},(d)=>{
                this.regionData=d
                this.regionData=this.regionData.filter(e=>{return e.country_id==this.country_id})
                    
            },(e)=>{
                console.log("error",e);
            },()=>{
                console.log("finishing");
            });
            axios_get("/trainee",{},(d)=>{
                console.log(d)
                this.refresherTraineeData=d.filter(element=>{
                    return element.region.country_id == this.countries.country_id && element.refresher_date!="None"
                });
                this.refresherTraineeData=this.refresherTraineeData.map(element=>{
                    element.region_name =element.region.name
                    return element
                });
                console.log(this.refresherTraineeData)
                /* this.refresherTraineeData=this.refresherTraineeData.sort((a, b)=>{
                    if(a.name_and_surname < b.name_and_surname) return -1;
                    else return 1;
                }) */
            },(e)=>{
                console.log("error",e);
            },()=>{
                console.log("finishing");
            });
        },
        methods: {
            del(){
                this.loader=true
                this.$refs['modal-delete'].hide()
                console.log(this.selectedId)
                axios_delete("/trainee/"+this.selectedId.id,{},
                    ()=>{
                        axios_get("/trainee",{},(d)=>{
                            this.refresherTraineeData=d.filter(element=>{
                                return element.region.country_id == this.countries.country_id && element.refresher_date!="None"
                            });
                            this.refresherTraineeData=this.refresherTraineeData.map(element=>{
                                element.region_name =element.region.name
                                return element
                            });
                            this.refresherTraineeData=this.refresherTraineeData.sort((a, b)=>{
                                if(a.name_and_surname < b.name_and_surname) return -1;
                                else return 1;
                            })
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        });
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader=false
                    }
                );
            },
            hideModal() {
                this.$refs['modal-delete'].hide()
            },
            view(e){
                console.log(e)
                /* const spl = e.refresher_date.split(" - ");
                const d1 = spl[0].split("/").reverse().join("-")
                const d2 = spl[1].split("/").reverse().join("-") */
                this.selectedId = e
            },
            eidt(e){
                console.log(e)
                const spl = e.refresher_date.split(" - ");
                const d1 = spl[0].split("/").reverse().join("-")
                const d2 = spl[1].split("/").reverse().join("-")
                this.selectedId = e
                this.srefresher_date = [new Date(d1), new Date(d2)]
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            updateTrainee(){
                this.loader=true
                this.$refs['update-trainee'].hide()
                console.log(this.selectedId.refresher_date)
                let dateinfo = this.srefresher_date[0].toLocaleString();
                let dateinfo1 = this.srefresher_date[1].toLocaleString();
                console.log(dateinfo)
                console.log(dateinfo1)
                let splitdata = dateinfo.split(" ");
                console.log(splitdata[0])
                let splitdata1 = dateinfo1.split(" ");
                console.log(splitdata1[0])
                console.log(splitdata[0] +" - "+splitdata1[0])
                this.selectedId.refresher_date = splitdata[0] +" - "+splitdata1[0];

                
                console.log(this.selectedId)

                axios_put("/trainee/"+this.selectedId.id,this.selectedId,
                    (d)=>{
                        console.log(d);
                        axios_get("/trainee",{},(d)=>{
                            console.log(d)
                            this.refresherTraineeData=d.filter(element=>{
                                return element.region.country_id == this.countries.country_id && element.refresher_date!="None"
                            });
                            this.refresherTraineeData=this.refresherTraineeData.map(element=>{
                                element.region_name =element.region.name
                                return element
                            });
                            this.refresherTraineeData=this.refresherTraineeData.sort((a, b)=>{
                                if(a.name_and_surname < b.name_and_surname) return -1;
                                else return 1;
                            })
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        });
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        this.loader=false
                        console.log("finishing");
                    })
            },

            addTrainee(){
                this.loader=true
                console.log(this.value.refresher_date)
                this.$refs['modal-standard'].hide()
                let dateinfo = this.srefresher_date[0].toLocaleString();
                let dateinfo1 = this.srefresher_date[1].toLocaleString();
                console.log(dateinfo)
                console.log(dateinfo1)
                let splitdata = dateinfo.split(" ");
                console.log(splitdata[0])
                let splitdata1 = dateinfo1.split(" ");
                console.log(splitdata1[0])
                console.log(splitdata[0] +" - "+splitdata1[0])
                this.value.refresher_date = splitdata[0] +" - "+splitdata1[0];

                
                console.log(this.selectedId)

                axios_put("/trainee/"+this.value.id,this.value,
                    (d)=>{
                        console.log(d);
                        axios_get("/trainee",{},(d)=>{
                            console.log(d)
                            this.refresherTraineeData=d.filter(element=>{
                                return element.region.country_id == this.countries.country_id && element.refresher_date!="None"
                            });
                            this.refresherTraineeData=this.refresherTraineeData.map(element=>{
                                element.region_name =element.region.name
                                return element
                            });
                            this.refresherTraineeData=this.refresherTraineeData.sort((a, b)=>{
                                if(a.name_and_surname < b.name_and_surname) return -1;
                                else return 1;
                            })
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        });
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader=false
                    })
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;
    }

    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }

</style>
